<template>
	<update-assets />
</template>
<script>
import UpdateAssets from '@/components/inventory/assets/AssetsForm/AssetsForm.vue';

export default {
	name: 'CreateAssetsPage',
	components: { UpdateAssets },
};
</script>
